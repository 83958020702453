import React from "react";
import StufuLogo from "./StufuLogo";
import Routes from "./Routes";

const Layout = ({ children }) => {
  return (
    <>
      <header>
        <div className="logo">
          <StufuLogo />
        </div>
      </header>
      <Routes />
      <footer>
        <div className="copyright">{`© ${new Date().getFullYear()} digitalsinn.com`}</div>
      </footer>
    </>
  );
};

export default Layout;
