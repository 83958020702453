import React, { useState, useRef, useEffect } from "react";
import Cookies from "universal-cookie";
import useAPI from "../hooks/useAPI";
import FormElement from "../components/FormElement";
import LoadingSpinner from "./LoadingSpinner";

const StreamView = ({ streamId }) => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [streamType, setStreamType] = useState("");
  const [streamUrl, setStreamUrl] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const { clientLogin, getStreamType } = useAPI();
  const [showSpinner, setShowSpinner] = useState(true);
  const cookies = new Cookies();
  const clientLoginForm = useRef();
  const vidContainer = useRef();
  const OvenPlayer = window.OvenPlayer;

  useEffect(() => {
    let formData = new FormData();
    formData.append("id", streamId);
    getStreamType(formData)
      .then((res) => {
        if (res.hasOwnProperty("type")) {
          setStreamType(res.type);
          setStreamUrl(res.url);
          setShowSpinner(false);
        }
      })
      .catch((err) => err);
    //eslint-disable-next-line
  }, []);

  function sorryNoStreamAvailable(player) {
    console.log("Sorry, there is no stream available.");
    try {
      player.remove();
      setError("Sorry, there is no stream available.");
    } catch (err) {
      console.log(err);
    }
  }

  function initOven(url, key) {
    //OvenPlayer.debug(true);
    let webrtcSources = OvenPlayer.generateWebrtcUrls([
      {
        host: url,
        application: "app",
        stream: key,
        label: "Studio Funk",
      },
    ]);
    let player = OvenPlayer.create("video", {
      sources: webrtcSources,
      controls: true,
      autoStart: true,
    });
    player.on("error", function (error) {
      sorryNoStreamAvailable(player);
      console.log(error);
    });
    player.on("ready", function () {
      setShowSpinner(false);
      setTimeout((_) => {
        player.play();
      }, 2000);
    });
  }

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setError("");
    const formData = new FormData(clientLoginForm.current);
    const req = await clientLogin(formData);
    if (req.isAuthenticated) {
      cookies.set("token", req.token);
      formData.append("token", req.token);
      setShowSpinner(true);
      setIsAuth(true);
      initOven(streamUrl, streamType);
    } else {
      setError("Wrong Credentials! Please try agian.");
    }
  };

  return (
    <div className="stream-view-container">
      {error ? (
        <div
          className="error-message-container"
          style={{
            maxWidth: "50vw",
            margin: "0 auto",
            transform: "translateY(100px)",
          }}
        >
          <p>{error}</p>
        </div>
      ) : null}
      {isAuth ? (
        <div className="container" style={{ textAlign: "center" }}>
          {showSpinner ? (
            <LoadingSpinner style={{ top: "0", left: "0" }} />
          ) : null}
          <video
            id="video"
            width="640"
            controls
            autoPlay
            ref={vidContainer}
          ></video>
        </div>
      ) : (
        <>
          {showSpinner ? <LoadingSpinner /> : null}
          <form id="login-form" onSubmit={onSubmit} ref={clientLoginForm}>
            <h2 className="title">Login</h2>
            {error ? (
              <div className="error-message-container">
                <p>{error}</p>
              </div>
            ) : null}
            <FormElement
              icon="vpn_key"
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
              required
            />
            <FormElement
              name="id"
              value={streamId}
              type="hidden"
              parentClass="no-margin hidden-before-after"
            />
            <FormElement
              name="stream_type"
              value={streamType}
              type="hidden"
              parentClass="no-margin hidden-before-after"
            />
            <FormElement
              id="submit"
              type="submit"
              parentClass="hidden-before-after"
            />
          </form>
        </>
      )}
    </div>
  );
};

export default StreamView;
